.section-title {
  position: relative;
  padding-bottom: 1rem;
}

.divider {
  width: 60px;
  height: 4px;
  background-color: #007bff;
  margin: 1rem auto;
  border-radius: 2px;
}

.text-primary {
  color: #007bff !important;
  font-weight: bold;
}

.card-body.p-5 {
  padding: 3rem !important;
  pointer-events: none;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
  pointer-events: none;
}

.bg-white {
  background-color: #ffffff !important;
}

.carousel-img {
  max-height: 500px; /* Altezza massima per tutte le immagini */
  object-fit: cover; /* Le immagini mantengono il loro aspetto */
}

.background-image-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.background-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* opacity: 0.1; */
  pointer-events: none; /* Disabilita qualsiasi effetto hover */
}

.card-body {
  position: relative;
  z-index: 2;
  pointer-events: none;
}

.fixed-dimension {
  width: 100%;
  height: 300px; /* Adjust the height as needed */
  object-fit: cover; /* Ensures the image covers the area without distortion */
}
