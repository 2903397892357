/*☑️NAVABR */

/* sottolineatura */
/* .nav-link.active span {
  border-bottom: 2px solid white;
}

.nav-link span {
  transition: border-bottom 0.3s ease;
}

.nav-link:hover span {
  border-bottom: 2px solid white;
}

.navbar-purple {
  background-color: #6b3291;
} */

/* --- */
/* Arrotonda la navbar e la centra */
/* .custom-navbar {
  background-color: #6b3291; 
  border-radius: 50px;
  padding: 10px 20px; 
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); 
  max-width: 90%; 
  margin: 20px auto; 
  transition: background-color 0.3s ease; 
}


.navbar-transparent {
  background-color: rgba(107, 50, 145, 0.9); 
  transition: background-color 0.3s ease;
}

.nav-link.active span {
  border-bottom: 2px solid white; 
}

.nav-link span {
  transition: border-bottom 0.3s ease;
}


.nav-link:hover span {
  border-bottom: 2px solid white;
}


.nav-link:hover {
  background-color: rgba(255, 255, 255, 0.2); 
  border-radius: 20px;
}


.footer-icon {
  transition: transform 0.3s ease;
}

.footer-icon:hover {
  transform: scale(1.1); 
} */

/* Navbar compatta arrotondata */
.custom-navbar {
  background-color: rgba(107, 50, 145, 0.8); /* Colore viola con trasparenza */
  border-radius: 40px; /* Arrotondamento */
  padding: 10px 20px; /* Spaziatura interna */
  max-width: 800px; /* Aumenta la larghezza per accomodare tutte le voci */
  position: absolute;
  top: 20px; /* Posizionata in alto */
  right: 20px; /* Posizionata a destra */
  z-index: 1000; /* Assicurati che la navbar sia sopra il contenuto */
  display: flex; /* Flexbox per disporre gli elementi in orizzontale */
  justify-content: space-between; /* Spaziatura tra gli elementi */
  align-items: center; /* Centra verticalmente il contenuto */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Ombra leggera */
}

/* Stile delle voci di menu */
.custom-navbar .nav-link {
  color: white; /* Colore del testo bianco */
  font-family: "Merriweather", serif;
  font-size: 14px;
  margin: 0 10px; /* Distanza tra le voci del menu */
  text-decoration: none; /* Rimuovi la sottolineatura */
  transition: color 0.3s ease;
}

.custom-navbar .nav-link:hover {
  color: #ff69b4; /* Colore al passaggio del mouse */
}

/* Stile per il logo */
.custom-navbar img {
  max-width: 60px; /* Riduci la dimensione del logo */
  margin-right: 15px; /* Distanza tra logo e menu */
}
/* Nascondi il pulsante hamburger sui dispositivi desktop */
.hamburger-container {
  display: none;
}

/* Mostra e fissa il pulsante hamburger sui dispositivi mobili */
@media (max-width: 767px) {
  .hamburger-container {
    display: block;
    /* position: fixed !important;  */
    top: 20px;
    right: 20px;
    z-index: 1000;
    transform: translateZ(0); /* Aggiunge una trasformazione per evitare eventuali bug nel rendering */
  }

  /* Icona hamburger */
  .hamburger-icon {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 30px;
    height: 20px;
  }

  .hamburger-icon .line {
    width: 100%;
    height: 3px;
    background-color: rgba(107, 50, 145, 0.8);
  }
}
