.table thead th {
  background-color: #f8f9fa;
  text-align: center;
}

.table tbody td {
  text-align: center;
}

.card {
  border: none;
}

.card-body {
  padding: 1.5rem;
}

.card h2 {
  font-family: "Montserrat", sans-serif;
  margin-bottom: 1rem;
}

.container h2 {
  font-family: "Montserrat", sans-serif;
  margin-bottom: 2rem;
}

/* Default margin-top */

/* Mobile grande: 425px */
@media (max-width: 425px) {
  #responsive {
    margin-top: 55px;
  }
}

/* Mobile grande: 425px */
@media (max-width: 769px) {
  #responsive {
    margin-top: 2px;
  }
}

.custom-table th,
.custom-table td {
  text-align: left;
  padding: 8px;
  vertical-align: middle;
}

.custom-table th {
  background-color: #f8f9fa;
  font-weight: bold;
}

.custom-table tbody tr:nth-child(odd) {
  background-color: #f2f2f2;
}

.custom-table tbody tr:hover {
  background-color: #e9ecef;
}

.custom-table td {
  font-size: 16px;
}
