.fixed-dimension {
  width: 300%;
  height: 500px; /* o qualunque altezza desideri */
  object-fit: cover;
  /* border-radius: 10px; */
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.fixed-dimension:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

.margin-top-custom {
  margin-top: 60px; /* o qualunque altezza desideri */
}

.section-title-cut {
  font-family: "Montserrat, sans-serif";
  font-size: 27px;
  color: #333;
  text-align: center;
  margin-bottom: 40px;
  padding: 10px;
  background-color: #f8f9fa;

  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 768px) {
  .my-text {
    text-align: left; /* Sostituisci "my-text" con la tua classe CSS */
  }
}

.my-text {
  hyphens: auto; /* Sostituisci "my-text" con la tua classe CSS */
}

.my-text {
  line-height: 1.2; /* Sostituisci "my-text" con la tua classe CSS e regola il valore come necessario */
}
