/* RESPONSIVE VIDEO HOME */
@media only screen and (max-width: 400px) {
  #video {
    width: 100%;
    margin-top: 40px;
  }
}

@media only screen and (max-width: 450px) {
  #video {
    width: 100%;
    margin-top: 100px;
  }
}

@media only screen and (max-width: 769px) {
  #video {
    width: 100%;
    margin-top: 1px;
  }
}

@media screen and (min-width: 1024px) {
  .adatta {
    font-size: 70px;
  }
}

.profile-img {
  width: 150px;
  height: 150px;
  object-fit: cover;
}

.about-text {
  font-family: "Montserrat", sans-serif;
  color: #666;
  line-height: 1.6;
  margin-bottom: 1rem;
}

@media (max-width: 768px) {
  .profile-img {
    width: 100px;
    height: 100px;
  }
}

/* Riduzione del margin-top per tablet (768px) */

@media only screen and (max-width: 668px) {
}

@media only screen and (max-width: 769px) {
}

@media only screen and (max-width: 1026px) {
}

@media only screen and (max-width: 1444px) {
}

/* Personalizzare il contorno di focus */
.btn:focus {
  box-shadow: 0 0 0 2px rgba(158, 96, 107, 0.5);
}
/* spazio sopra la scritta about */
.card-title {
  font-size: 18px;

  margin-top: 50px;
}

.about-section p {
  text-align: justify;
}

.about-title {
  font-size: 24px;
  color: #333;
}

.about-section .about-row {
  display: flex;
  align-items: center;
}

.card-text {
  margin-bottom: 20px;
  margin-top: 20px;
}

/* CARD INTERESSI  */
.col-md-2 {
  margin-right: -7px;
}

.card-sm {
  max-width: 150px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
  margin-bottom: -10px;
}

.card .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* Overlay con gradiente */
  opacity: 0;
  transition: opacity 0.3s ease;
  border-radius: 10px;
}

.card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.card:hover .overlay {
  opacity: 1;
}

.card .card-body {
  text-align: center;
}

.interest-image {
  position: relative;
  overflow: hidden;
}

.interest-img {
  width: 100%;
  height: 100px;
  object-fit: cover;
  transition: transform 0.3s;
}

.hover-text {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 5px;
  margin: 0;
  opacity: 0;
  transition: opacity 0.3s;
}

.interest-image:hover .hover-text {
  opacity: 1;
}

.interest-image:hover .interest-img {
  transform: scale(1.1);
}

.interest-image {
  position: relative;
  overflow: hidden;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

/* prova */
.about-section {
  text-align: center;
  padding: 60px 0;
  background-color: #f6f6f6;
}

.about-logo {
  width: 120px;
  height: 120px;
  margin: 0 auto 20px auto;
  border-radius: 50%;
  border: 3px solid #5f307b;
}

.about-title {
  font-size: 24px;
  color: #5d1657;
  margin-bottom: 15px;
  font-family: "Merriweather", serif;
}

.about-text {
  font-size: 16px;
  color: #555;
  max-width: 800px;
  margin: 0 auto;
  line-height: 1.6;
}

.about-images {
  display: flex;
  justify-content: center;
  margin-top: 40px;
  gap: 30px;
}

.about-images img {
  height: 450px; /* Imposta l'altezza fissa */
  width: auto; /* La larghezza si adatta proporzionalmente */
}

.about-images img {
  width: 20%;
  border-radius: 10px;
  transition: transform 0.3s ease;
}

.about-images img:hover {
  transform: scale(1.05);
}

@media (max-width: 768px) {
  .about-images img {
    width: 45%;
  }
}

@media (max-width: 480px) {
  .about-images {
    flex-direction: column;
    align-items: center;
  }

  .about-images img {
    width: 80%;
    margin-bottom: 20px;
  }
}

/* orari del salone */
/* .cut-container {
  position: relative;
  background-image: url("../assets/home//FOGLIE.png");
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  height: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cut-overlay {
  background-color: rgba(0, 0, 0, 0.3);
  color: white;
  padding: 20px;
  text-align: center;
  width: 100%;
}

.giorno,
.orario {
  font-weight: bold;
}

ul {
  list-style-type: none;
  padding: 0;
}

.text-background {
  background-color: rgba(0, 0, 0, 0.5);
  padding: 10px 27px; 
  border-radius: 0px; 
  display: inline-block; 
  max-width: 800px; 
  margin: 0 auto; 
} */

/* orari del salone */
.cut-container {
  position: relative;
  background-image: url("../assets/home/FOGLIE.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat; /* Impedisce la ripetizione dello sfondo */
  background-attachment: fixed;
  min-height: 600px; /* Altezza minima per garantire spazio sufficiente */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.cut-overlay {
  background-color: rgba(0, 0, 0, 0.3);
  color: white;
  padding: 20px;
  text-align: center;
  width: 100%;
}

.giorno,
.orario {
  font-weight: bold;
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.text-background {
  background-color: rgba(0, 0, 0, 0.5); /* Sfondo semi-trasparente */
  padding: 10px 27px;
  border-radius: 10px;
  display: inline-block;
  max-width: 800px;
  margin: 20px auto;
  overflow: hidden;
}

/* Media query per schermi piccoli (tablet e smartphone) */
@media (max-width: 768px) {
  .cut-container {
    min-height: 400px; /* Altezza minima ridotta per schermi più piccoli */
    background-attachment: scroll;
    padding: 10px;
  }

  .text-background {
    max-width: 90%;
    padding: 15px;
  }

  ul {
    font-size: 1rem;
  }

  .cut-overlay {
    padding: 8px;
  }
}

/* Media query per schermi molto piccoli (smartphone) */
@media (max-width: 480px) {
  h1 {
    font-size: 1.5rem;
  }

  ul {
    font-size: 0.9rem;
    line-height: 1.5;
  }

  .text-background {
    padding: 10px;
  }

  .cut-overlay {
    padding: 5px;
  }
}
