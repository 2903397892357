/* src/Cut.css */
.cut-container {
  position: relative;
  width: 100%;
  height: 100vh;
  background-image: url("../assets//salone/1.jpg");
  background-size: cover;
  background-position: center;
}

/* http://www.artparis.it/assets/img/Negozio/8.jpg */
.cut-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  text-align: center;
}

.cut-overlay h1 {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.cut-overlay ul {
  list-style-type: none;
  padding: 0;
}

.cut-overlay li {
  font-size: 1.5rem;
  margin: 10px 0;
}

.cut-overlay .giorno {
  font-weight: bold;
}

.cut-overlay .orario {
  font-style: italic;
}
