.footer {
  background-color: #0b1315;
  color: #fff;
  text-align: center;
  padding: 20px;
  position: relative;
}

.footer-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
}

.footer-content p {
  margin: 0 0 10px 0; /* Aggiungi margine inferiore per separare dal resto */
  font-size: 14px;
}

.social-icons {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.social-icons a {
  margin: 0 10px;
  display: inline-block;
}

.footer-icon {
  width: 24px;
  height: 24px;
}

.footer-image {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.footer-image img {
  max-width: 100%;
  height: auto;
}

/* #f06292 */
.scroll-to-top {
  position: absolute;
  right: 19px;
  bottom: 20px;
  background-color: #feaab2;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.scroll-to-top svg {
  fill: #fff;
}

.footer-icon {
  filter: brightness(0) invert(1);
}
